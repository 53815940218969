<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">单位</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >组织机构</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >组织机构</el-breadcrumb-item
        >
        <el-breadcrumb-item>单位</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">公司编码:</div>
          <el-input v-model="number" placeholder="请输入公司编码"></el-input>
        </div>
        <div class="screen-input-box">
          <div class="title-box">公司名称:</div>
          <el-input v-model="name" placeholder="请输入公司名称"></el-input>
        </div>

        <button class="btn-pre" @click="getCompanies(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-add" @click="newBuilt" v-if="powers.add">
          <i class="fa ion-plus-round"></i>新建
        </button>
      </div>
      <div class="table-box">
        <el-table
          ref="doLayout"
          :data="tableData"
          border
          height="500"
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="ID" label="公司id" width="80" />
          <el-table-column prop="CompanyCHS" label="公司中文名称" width="80" />
          <el-table-column prop="CompanyEN" label="公司英文名称" width="80" />
          <el-table-column prop="ProjectNames" label="上级项目部" width="80" />
          <el-table-column prop="CompanyNumber" label="编码" width="80" />
          <el-table-column prop="Abbreviation" label="缩写" width="80" />
          <el-table-column
            prop="SubjectTypeName"
            label="主体分类名称"
            width="80"
          />
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <i
                class="fa fa-edit"
                v-if="powers.update"
                @click="edit(scope.row)"
              ></i>
              <i
                class="fa fa-trash"
                v-if="powers.update"
                @click="deleteCompanies(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getCompanies(1)"
          @current-change="getCompanies"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="dialogType === 1 ? '新建单位' : '编辑单位'"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
      top="13vh"
    >
      <div class="dialog-main">
        <div class="line-box">
          <div class="from-box">
            <div class="name">公司中文名称:</div>
            <el-input
              v-model="parameter.NameCHS"
              placeholder="公司中文名称"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">公司英文名称</div>
            <el-input
              v-model="parameter.NameEN"
              placeholder="公司英文名称"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">成立日期</div>
            <el-date-picker
              :picker-options="pickerOptionsStart"
              v-model="parameter.Dateline"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>

          <div class="from-box">
            <div class="name">编码:</div>
            <el-input v-model="parameter.Number" placeholder="编码"></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">缩写:</div>
            <el-input
              v-model="parameter.Abbreviation"
              placeholder="缩写"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">主体分类名称:</div>
            <el-input
              v-model="parameter.SubjectTypeName"
              placeholder="主体分类名称"
            ></el-input>
          </div>
        </div>

        <div class="line-box">
          <div class="single">
            <div class="name">上级项目部:</div>
            <el-cascader
              v-model="parameter.ProjectIds"
              :options="tree"
              :props="props"
              clearable
            ></el-cascader>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">取消</el-button
        ><el-button class="pop-save" @click="addCompanies">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { auditRecords } from "@/utils/tableData";
import {
  getCompanies,
  getProjectsTree,
  addCompanies,
  updateCompanies,
  deleteCompanies,
} from "@/api/user";
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      auditRecords: auditRecords,
      dialogVisible: false,
      dialogType: 1, //1新建2、编辑
      options1: [
        {
          value: 1,
          label: "正在进行",
        },
        {
          value: 2,
          label: "项目暂停",
        },
        {
          value: 3,
          label: "项目结束",
        },
      ],
      options2: [
        {
          value: 1,
          label: "展示",
        },
        {
          value: 2,
          label: "不展示",
        },
      ],
      senior: false,
      value1: null,
      value2: null,
      value3: null,
      page: 1,
      size: 10,
      total: null,
      number: null,
      name: null,
      Id: 0,
      ParentId: 0,
      DepartmentId: 0,
      parameter: {
        Id: 0,
        ProjectIds: [],
        Code: "",
        NameCHS: "",
        NameEN: "",
        Enable: true,
        State: 1,
        Number: "",
        Abbreviation: "",
        Dateline: null,
        SubjectTypeName: "",
      },
      tree: [],
      props: {
        value: "ProjectID",
        label: "ProjectName",
        children: "Children",
        checkStrictly: true,
      },
      pickerOptionsStart: {
        disabledDate: (time) => {
          return time.getTime() < Date.parse("1899-12-31");
        },
      },
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      loading: true,
    };
  },
  filters: {
    timeFormater(value) {
      value = value.substring(0, 10);
      return value;
    },
  },
  methods: {
    deleteItem(index) {
      this.messageList.splice(index, 1);
    },
    handleNoticeClose() {
      this.noticeDrawer = false;
    },

    handleClose() {
      this.dialogVisible = false;
      this.parameter = {
        Id: 0,
        ProjectIds: [],
        Code: "",
        NameCHS: "",
        NameEN: "",
        Enable: true,
        State: 1,
        Number: "",
        Abbreviation: "",
        Dateline: null,
        SubjectTypeName: "",
      };
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    newBuilt() {
      this.dialogVisible = true;
      this.dialogType = 1;
      this.roleName = "";
      this.enable = false;
    },
    edit(row) {
      this.dialogVisible = true;
      this.dialogType = 2;

      const multipleSelectionItem = row;

      this.parameter = {
        Id: multipleSelectionItem.ID,
        ProjectIds: [],
        Code: multipleSelectionItem.Code,
        NameCHS: multipleSelectionItem.CompanyCHS,
        NameEN: multipleSelectionItem.CompanyEN,
        Enable: multipleSelectionItem.IsEnabled === 1 ? true : false,
        State:
          multipleSelectionItem.State === null
            ? 1
            : multipleSelectionItem.State,
        Number: multipleSelectionItem.CompanyNumber,
        Abbreviation: multipleSelectionItem.Abbreviation,
        Dateline: multipleSelectionItem.Dateline,
        SubjectTypeName: multipleSelectionItem.SubjectTypeName,
      };
      if (multipleSelectionItem.ProjectIds) {
        this.parameter.ProjectIds = [];
        const ProjectIds = multipleSelectionItem.ProjectIds.split(",");
        ProjectIds.forEach((item) => {
          let newItem = parseInt(item);
          this.parameter.ProjectIds.push(newItem);
        });
      }
    },

    doLayout() {
      this.$nextTick(() => {
        this.$refs.doLayout.doLayout();
      });
    },

    //获取单位列表
    getCompanies(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        number: this.number,
        name: this.name,
      };
      getCompanies(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },

    //添加编辑单位
    addCompanies() {
      if (this.dialogType === 1) {
        //新增
        addCompanies(this.parameter).then((res) => {
          if (res.status === 200) {
            this.$message.success("创建成功");
            this.getCompanies();
            this.handleClose();
          }
        });
      } else {
        updateCompanies(this.parameter).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.getCompanies();
            this.handleClose();
          }
        });
      }
    },
    //删除单位
    deleteCompanies(row) {
      let data = { id: "" };
      data.id = row.ID;

      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteCompanies(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getCompanies();
          }
        });
      });
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
  },

  created() {
    this.getCompanies();
    getProjectsTree().then((res) => {
      if (res.status === 200) {
        this.tree = res.response;
      }
    });
    this.getButtonPower();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 12px;
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-left: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 65px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
    }
  }
  .dialog-main {
    border-bottom: 2px solid #e6e6e6;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 340px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
          margin-right: 10px;
        }
        .el-input {
          width: 250px;
        }
        .el-select {
          width: 250px;
        }
      }
      .single {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 24px;
        .name {
          flex-shrink: 0;
          width: 80px;
          margin-right: 10px;
        }
        .el-cascader {
          width: 100%;
        }
      }
    }
  }
  .dialog-footer {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 2px solid #e6e6e6;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> .el-table__placeholder {
  width: 23px;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
</style>
